
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #32393f
}

a {
	color: #008cff;
}

.table-responsive {
	white-space: nowrap;
}

.form-check-input:checked {
	background-color: #008cff;
	border-color: #008cff
}

.form-check-input[type=checkbox]:indeterminate {
	background-color: #008cff;
	border-color: #008cff;
}

.valid-feedback {
	color: #15ca20
}


.form-control.is-valid, .was-validated .form-control:valid {
	border-color: #15ca20;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
	border-color: #15ca20;
}

.form-select.is-valid, .was-validated .form-select:valid {
	border-color: #15ca20;
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
	border-color: #15ca20;
}
.form-check-input.is-valid, .was-validated .form-check-input:valid {
	border-color: #15ca20
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
	background-color: #15ca20
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
	color: #15ca20
}

.invalid-feedback {
	color: #fd3550
}


.form-control.is-invalid, .was-validated .form-control:invalid {
	border-color: #fd3550;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
	border-color: #fd3550;
}
.form-select.is-invalid, .was-validated .form-select:invalid {
	border-color: #fd3550;
}
.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
	border-color: #fd3550;
}
.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
	border-color: #fd3550
}
.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
	background-color: #fd3550
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
	color: #fd3550
}

.btn {
    letter-spacing: .5px;
}

.btn-primary {
	color: #fff;
	background-color: #008cff;
	border-color: #008cff
}
.btn-primary:hover {
	color: #fff;
	background-color: #037de2;
	border-color: #037de2
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
	color: #fff;
	background-color: #037de2;
	border-color: #037de2;
	box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #037de2;
	border-color: #037de2
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}
.btn-primary.disabled, .btn-primary:disabled {
	color: #fff;
	background-color: #008cff;
	border-color: #008cff
}


.btn-success {
	color: #fff;
	background-color: #15ca20;
	border-color: #15ca20
}
.btn-success:hover {
	color: #fff;
	background-color: #0eb018;
	border-color: #0eb018
}
.btn-check:focus+.btn-success, .btn-success:focus {
	color: #fff;
	background-color: #0eb018;
	border-color: #0eb018;
	box-shadow: 0 0 0 .25rem rgb(23 160 14 / 52%)
}
.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #0eb018;
	border-color: #0eb018
}
.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgb(23 160 14 / 52%)
}
.btn-success.disabled, .btn-success:disabled {
	color: #fff;
	background-color: #0f8107;
	border-color: #146c43
}


.btn-danger {
	color: #fff;
	background-color: #fd3550;
	border-color: #fd3550
}
.btn-danger:hover {
	color: #fff;
	background-color: #d10b1e;
	border-color: #b02a37
}
.btn-check:focus+.btn-danger, .btn-danger:focus {
	color: #fff;
	background-color: #d10b1e;
	border-color: #b02a37;
	box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}
.btn-check:active+.btn-danger, .btn-check:checked+.btn-danger, .btn-danger.active, .btn-danger:active, .show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #b02a37;
	border-color: #a52834
}
.btn-check:active+.btn-danger:focus, .btn-check:checked+.btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}
.btn-danger.disabled, .btn-danger:disabled {
	color: #fff;
	background-color: #fd3550;
	border-color: #fd3550
}


.btn-outline-primary {
	color: #008cff;
	border-color: #008cff
}
.btn-outline-primary:hover {
	color: #fff;
	background-color: #008cff;
	border-color: #008cff
}
.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}
.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
	color: #fff;
	background-color: #008cff;
	border-color: #008cff
}
.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	color: #008cff;
	background-color: transparent
}


.btn-outline-success {
	color: #15ca20;
	border-color: #15ca20
}
.btn-outline-success:hover {
	color: #fff;
	background-color: #15ca20;
	border-color: #15ca20
}
.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
	box-shadow: 0 0 0 .25rem rgb(23 160 14 / 52%)
}
.btn-check:active+.btn-outline-success, .btn-check:checked+.btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
	color: #fff;
	background-color: #15ca20;
	border-color: #15ca20
}
.btn-check:active+.btn-outline-success:focus, .btn-check:checked+.btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
	box-shadow: 0 0 0 .25rem rgb(23 160 14 / 52%)
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #15ca20;
	background-color: transparent
}


.btn-outline-danger {
	color: #fd3550;
	border-color: #fd3550
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #fd3550;
	border-color: #fd3550
}
.btn-check:focus+.btn-outline-danger, .btn-outline-danger:focus {
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}
.btn-check:active+.btn-outline-danger, .btn-check:checked+.btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
	color: #fff;
	background-color: #fd3550;
	border-color: #fd3550
}
.btn-check:active+.btn-outline-danger:focus, .btn-check:checked+.btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	color: #fd3550;
	background-color: transparent
}

.btn-link {
	font-weight: 400;
	color: #008cff;
	text-decoration: underline
}

.dropdown-item:focus, .dropdown-item:hover {
	color: #1e2125;
	background-color: #f8f9fa
}
.dropdown-item.active, .dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #008cff
}
.dropdown-item.disabled, .dropdown-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: transparent
}

.card {
    border: 0 solid transparent;
	margin-bottom: 1.5rem;
	box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.card-header {
	background-color: rgba(0, 0, 0, .0);
}

.progress-bar { 
    background-color: #008cff;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #008cff;
	border-color: #008cff
}

.border-primary {
	border-color: #008cff!important
}

.border-success {
	border-color: #15ca20!important
}

.border-danger {
	border-color: #fd3550!important
}



.text-primary {
	color: #008cff!important
}
.text-secondary {
	color: #6c757d!important
}
.text-success {
	color: #15ca20!important
}
.text-info {
	color: #0dcaf0!important
}
.text-warning {
	color: #ffc107!important
}
.text-danger {
	color: #fd3550!important
}
.text-light {
	color: #f8f9fa!important
}
.text-dark {
	color: #212529!important
}
.text-white {
	color: #fff!important
}
.text-body {
	color: #212529!important
}
.text-muted {
	color: #6c757d!important
}
.text-black-50 {
	color: rgba(0, 0, 0, .5)!important
}
.text-white-50 {
	color: rgba(255, 255, 255, .5)!important
}



.bg-primary {
	background-color: #008cff !important;
  }

.bg-success {
	background-color: #15ca20 !important;
  }

.bg-danger {
	background-color: #fd3550 !important;
  }













