@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700,400,300);
.lobibox .lobibox-btn.lobibox-btn-cancel.active, .lobibox .lobibox-btn.lobibox-btn-cancel:active, .lobibox .lobibox-btn.lobibox-btn-default.active, .lobibox .lobibox-btn.lobibox-btn-default:active, .lobibox .lobibox-btn.lobibox-btn-no.active, .lobibox .lobibox-btn.lobibox-btn-no:active, .lobibox .lobibox-btn.lobibox-btn-ok.active, .lobibox .lobibox-btn.lobibox-btn-ok:active, .lobibox .lobibox-btn.lobibox-btn-yes.active, .lobibox .lobibox-btn.lobibox-btn-yes:active, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-cancel, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-default, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-no, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-ok, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-yes {
	background-image: none
}

.lobibox, .lobibox-notify {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif
}

.lobibox-notify-wrapper-large.bottom, .lobibox-notify-wrapper.bottom {
	bottom: 0
}

.animated-super-fast {
	-webkit-animation-duration: .3s;
	animation-duration: .3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.animated, .animated-fast {
	-webkit-animation-fill-mode: both
}

.animated-fast {
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	animation-fill-mode: both
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	animation-fill-mode: both
}

.animated-slow {
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

@-webkit-keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px)
	}
}

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		transform: translateY(0)
	}
	40% {
		transform: translateY(-30px)
	}
	60% {
		transform: translateY(-15px)
	}
}

@-webkit-keyframes jumpUp {
	0% {
		-webkit-transform: translate3d(0, calc(230%), 0) scale3d(0, 1, 1);
		-webkit-animation-timing-function: ease-in
	}
	40% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(.02, 1.1, 1);
		-webkit-animation-timing-function: ease-out
	}
	70% {
		-webkit-transform: translate3d(0, -40px, 0) scale3d(.8, 1.1, 1)
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
	}
}

@keyframes jumpUp {
	0% {
		transform: translate3d(0, calc(230%), 0) scale3d(0, 1, 1);
		animation-timing-function: ease-in
	}
	40% {
		transform: translate3d(0, 0, 0) scale3d(.02, 1.1, 1);
		animation-timing-function: ease-out
	}
	70% {
		transform: translate3d(0, -40px, 0) scale3d(.8, 1.1, 1)
	}
	100% {
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
	}
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8)
	}
	50% {
		-webkit-transform: scale(.95)
	}
	80% {
		-webkit-transform: scale(1.05)
	}
	90% {
		-webkit-transform: scale(.98)
	}
	100% {
		-webkit-transform: scale(1)
	}
}

@keyframes expandOpen {
	0% {
		transform: scale(1.8)
	}
	50% {
		transform: scale(.95)
	}
	80% {
		transform: scale(1.05)
	}
	90% {
		transform: scale(.98)
	}
	100% {
		transform: scale(1)
	}
}

@keyframes fadeInScale {
	0% {
		transform: scale(0);
		opacity: 0
	}
	100% {
		transform: scale(1);
		opacity: 1
	}
}

@-webkit-keyframes fadeInScale {
	0% {
		-webkit-transform: scale(0);
		opacity: 0
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1
	}
}

@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
		transform: scale(.3)
	}
	50% {
		opacity: 1
	}
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
		-ms-transform: scale(.3);
		transform: scale(.3)
	}
	50% {
		opacity: 1
	}
}

@-webkit-keyframes zoomOut {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	50% {
		opacity: 0;
		-webkit-transform: scale(.3);
		transform: scale(.3)
	}
	100% {
		opacity: 0
	}
}

@keyframes zoomOut {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1)
	}
	50% {
		opacity: 0;
		-webkit-transform: scale(.3);
		-ms-transform: scale(.3);
		transform: scale(.3)
	}
	100% {
		opacity: 0
	}
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn
}

.zoomOut {
	-webkit-animation-name: zoomOut;
	animation-name: zoomOut
}

.bounce {
	-webkit-animation-name: bounce;
	animation-name: bounce
}

.jumpUp {
	-webkit-animation-name: jumpUp;
	animation-name: jumpUp
}

.expandOpen {
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen
}

.fadeInScale {
	animation-name: fadeInScale;
	-webkit-animation-name: fadeInScale
}

body.lobibox-open {
	overflow: hidden
}

.lobibox {
	position: fixed;
	z-index: 4001;
	-webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, .5);
	box-shadow: 0 0 20px 5px rgba(0, 0, 0, .5)
}

.lobibox * {
	box-sizing: border-box;
	-webkit-box-sizing: border-box
}

.lobibox .lobibox-header {
	font-size: 20px;
	padding: 5px 10px;
	color: #eee
}

.lobibox .lobibox-header .btn-close {
	float: right;
	background-color: transparent;
	cursor: pointer;
	border: none;
	outline: 0;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}

.lobibox .lobibox-header .btn-close:hover {
	text-shadow: 2px 2px 3px rgba(0, 0, 0, .7)
}

.lobibox .lobibox-body {
	overflow: hidden;
	display: table;
	position: relative;
	width: 100%;
	padding: 15px 20px;
	background-color: rgba(255, 255, 255, .98);
	font-size: 16px
}

.lobibox .lobibox-body .lobibox-icon-wrapper {
	position: relative;
	height: 100%;
	display: table;
	font-size: 60px
}

.lobibox .lobibox-body .lobibox-icon-wrapper .lobibox-icon {
	display: table-cell;
	vertical-align: middle
}

.lobibox .lobibox-body .lobibox-body-text-wrapper {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	padding-left: 10px
}

.lobibox .lobibox-footer {
	text-align: center;
	padding: 6px
}

.lobibox .lobibox-footer>* {
	margin: 0 10px 0 0
}

.lobibox .lobibox-footer.text-center {
	text-align: center
}

.lobibox .lobibox-footer.text-left {
	text-align: left
}

.lobibox .lobibox-footer.text-right {
	text-align: right
}

.lobibox.lobibox-confirm {
	border: none
}

.lobibox.lobibox-confirm .lobibox-header {
	color: #eee;
	background-color: #3C2D2D
}

.lobibox.lobibox-confirm .lobibox-body .lobibox-icon {
	color: #3C2D2D
}

.lobibox.lobibox-confirm .lobibox-footer {
	background-color: #594343
}

.lobibox.lobibox-success {
	border: 1px solid #29B87E
}

.lobibox.lobibox-success .lobibox-header {
	color: #eee;
	background-color: #29B87E
}

.lobibox.lobibox-success .lobibox-body .lobibox-icon {
	color: #29B87E
}

.lobibox.lobibox-success .lobibox-footer {
	background-color: #40d498
}

.lobibox.lobibox-error {
	border: 1px solid #CA2121
}

.lobibox.lobibox-error .lobibox-header {
	color: #eee;
	background-color: #CA2121
}

.lobibox.lobibox-error .lobibox-body .lobibox-icon {
	color: #CA2121
}

.lobibox.lobibox-error .lobibox-footer {
	background-color: #e03e3e
}

.lobibox.lobibox-info {
	border: 1px solid #2E79B4
}

.lobibox.lobibox-info .lobibox-header {
	color: #eee;
	background-color: #2E79B4
}

.lobibox.lobibox-info .lobibox-body .lobibox-icon {
	color: #2E79B4
}

.lobibox.lobibox-info .lobibox-footer {
	background-color: #4593d0
}

.lobibox.lobibox-warning {
	border: 1px solid #CE812E
}

.lobibox.lobibox-warning .lobibox-header {
	color: #eee;
	background-color: #CE812E
}

.lobibox.lobibox-warning .lobibox-body .lobibox-icon {
	color: #CE812E
}

.lobibox.lobibox-warning .lobibox-footer {
	background-color: #d99a56
}

.lobibox.lobibox-prompt {
	border: none
}

.lobibox.lobibox-prompt .lobibox-header {
	color: #eee;
	background-color: #3c2d2d
}

.lobibox.lobibox-prompt .lobibox-body {
	padding: 20px
}

.lobibox.lobibox-prompt .lobibox-body .lobibox-input {
	min-height: 38px;
	border: 1px solid #21cb91;
	width: 100%;
	padding: 5px;
	font-size: 18px;
	outline: 0
}

.lobibox.lobibox-prompt .lobibox-body .lobibox-input:focus {
	background-color: #EEE
}

.lobibox.lobibox-prompt .lobibox-body .lobibox-input.invalid {
	border-color: #DC2B2A
}

.lobibox.lobibox-prompt .lobibox-body .lobibox-input-error-message {
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 13px;
	color: #DC2B2A
}

.lobibox.lobibox-prompt .lobibox-footer {
	background-color: #594343
}

.lobibox.lobibox-progress .lobibox-header {
	background-color: #2F5D6D
}

.lobibox.lobibox-progress .lobibox-body {
	padding: 15px;
	font-size: 16px
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-bar-wrapper {
	position: relative;
	height: 20px;
	border: 1px solid #c3c3c3;
	border-radius: 10px;
	background-color: #d5d5d5
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-bar-wrapper .lobibox-progress-bar {
	width: 0;
	border-radius: 10px;
	background-color: #468ba2;
	height: 100%;
	text-align: center
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-bar-wrapper .lobibox-progress-text {
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-outer {
	margin-bottom: 0
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-outer .progress-bar {
	transition: none
}

.lobibox.lobibox-progress .lobibox-body .lobibox-progress-outer [data-role=progress-text] {
	font-weight: 700;
	color: rgba(0, 0, 0, .7)
}

.lobibox.lobibox-window {
	border: 3px solid #225EB8;
	border-radius: 6px
}

.lobibox.lobibox-window .lobibox-header {
	background-color: #225EB8;
	color: #eee;
	font-size: 18px
}

.lobibox.lobibox-window .lobibox-body {
	overflow: auto;
	display: block;
	font-size: 14px;
	padding: 15px;
	background-color: #f5f8fd
}

.lobibox.lobibox-window .lobibox-footer {
	background-color: #8ab0e9
}

.lobibox.lobibox-window :last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.lobibox.draggable .lobibox-header {
	cursor: move
}

.lobibox .lobibox-btn {
	display: inline-block;
	padding: 8px 14px;
	font-size: 14px;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 2px;
	line-height: initial
}

.lobibox .lobibox-btn.lobibox-btn-cancel {
	color: #FFF;
	background-color: #CA2121;
	border-color: #CA2121
}

.lobibox .lobibox-btn.lobibox-btn-cancel.active, .lobibox .lobibox-btn.lobibox-btn-cancel.focus, .lobibox .lobibox-btn.lobibox-btn-cancel:active, .lobibox .lobibox-btn.lobibox-btn-cancel:focus, .lobibox .lobibox-btn.lobibox-btn-cancel:hover, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-cancel {
	color: #FFF;
	background-color: #9e1a1a;
	border-color: #951818
}

.lobibox .lobibox-btn.lobibox-btn-cancel.disabled, .lobibox .lobibox-btn.lobibox-btn-cancel.disabled.active, .lobibox .lobibox-btn.lobibox-btn-cancel.disabled.focus, .lobibox .lobibox-btn.lobibox-btn-cancel.disabled:active, .lobibox .lobibox-btn.lobibox-btn-cancel.disabled:focus, .lobibox .lobibox-btn.lobibox-btn-cancel.disabled:hover, .lobibox .lobibox-btn.lobibox-btn-cancel[disabled], .lobibox .lobibox-btn.lobibox-btn-cancel[disabled].active, .lobibox .lobibox-btn.lobibox-btn-cancel[disabled].focus, .lobibox .lobibox-btn.lobibox-btn-cancel[disabled]:active, .lobibox .lobibox-btn.lobibox-btn-cancel[disabled]:focus, .lobibox .lobibox-btn.lobibox-btn-cancel[disabled]:hover, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel.active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel.focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel:active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel:focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel:hover {
	background-color: #CA2121;
	border-color: #CA2121
}

.lobibox .lobibox-btn.lobibox-btn-cancel .badge {
	color: #CA2121;
	background-color: #FFF
}

.lobibox .lobibox-btn.lobibox-btn-yes {
	color: #FFF;
	background-color: #29B87E;
	border-color: #29B87E
}

.lobibox .lobibox-btn.lobibox-btn-yes.active, .lobibox .lobibox-btn.lobibox-btn-yes.focus, .lobibox .lobibox-btn.lobibox-btn-yes:active, .lobibox .lobibox-btn.lobibox-btn-yes:focus, .lobibox .lobibox-btn.lobibox-btn-yes:hover, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-yes {
	color: #FFF;
	background-color: #208e61;
	border-color: #1e865c
}

.lobibox .lobibox-btn.lobibox-btn-yes.disabled, .lobibox .lobibox-btn.lobibox-btn-yes.disabled.active, .lobibox .lobibox-btn.lobibox-btn-yes.disabled.focus, .lobibox .lobibox-btn.lobibox-btn-yes.disabled:active, .lobibox .lobibox-btn.lobibox-btn-yes.disabled:focus, .lobibox .lobibox-btn.lobibox-btn-yes.disabled:hover, .lobibox .lobibox-btn.lobibox-btn-yes[disabled], .lobibox .lobibox-btn.lobibox-btn-yes[disabled].active, .lobibox .lobibox-btn.lobibox-btn-yes[disabled].focus, .lobibox .lobibox-btn.lobibox-btn-yes[disabled]:active, .lobibox .lobibox-btn.lobibox-btn-yes[disabled]:focus, .lobibox .lobibox-btn.lobibox-btn-yes[disabled]:hover, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes.active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes.focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes:active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes:focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes:hover {
	background-color: #29B87E;
	border-color: #29B87E
}

.lobibox .lobibox-btn.lobibox-btn-yes .badge {
	color: #29B87E;
	background-color: #FFF
}

.lobibox .lobibox-btn.lobibox-btn-no {
	color: #FFF;
	background-color: #0760B3;
	border-color: #0760B3
}

.lobibox .lobibox-btn.lobibox-btn-no.active, .lobibox .lobibox-btn.lobibox-btn-no.focus, .lobibox .lobibox-btn.lobibox-btn-no:active, .lobibox .lobibox-btn.lobibox-btn-no:focus, .lobibox .lobibox-btn.lobibox-btn-no:hover, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-no {
	color: #FFF;
	background-color: #054682;
	border-color: #054078
}

.lobibox .lobibox-btn.lobibox-btn-no.disabled, .lobibox .lobibox-btn.lobibox-btn-no.disabled.active, .lobibox .lobibox-btn.lobibox-btn-no.disabled.focus, .lobibox .lobibox-btn.lobibox-btn-no.disabled:active, .lobibox .lobibox-btn.lobibox-btn-no.disabled:focus, .lobibox .lobibox-btn.lobibox-btn-no.disabled:hover, .lobibox .lobibox-btn.lobibox-btn-no[disabled], .lobibox .lobibox-btn.lobibox-btn-no[disabled].active, .lobibox .lobibox-btn.lobibox-btn-no[disabled].focus, .lobibox .lobibox-btn.lobibox-btn-no[disabled]:active, .lobibox .lobibox-btn.lobibox-btn-no[disabled]:focus, .lobibox .lobibox-btn.lobibox-btn-no[disabled]:hover, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no.active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no.focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no:active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no:focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no:hover {
	background-color: #0760B3;
	border-color: #0760B3
}

.lobibox .lobibox-btn.lobibox-btn-no .badge {
	color: #0760B3;
	background-color: #FFF
}

.lobibox .lobibox-btn.lobibox-btn-ok {
	color: #FFF;
	background-color: #0760B3;
	border-color: #0760B3
}

.lobibox .lobibox-btn.lobibox-btn-ok.active, .lobibox .lobibox-btn.lobibox-btn-ok.focus, .lobibox .lobibox-btn.lobibox-btn-ok:active, .lobibox .lobibox-btn.lobibox-btn-ok:focus, .lobibox .lobibox-btn.lobibox-btn-ok:hover, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-ok {
	color: #FFF;
	background-color: #054682;
	border-color: #054078
}

.lobibox .lobibox-btn.lobibox-btn-ok.disabled, .lobibox .lobibox-btn.lobibox-btn-ok.disabled.active, .lobibox .lobibox-btn.lobibox-btn-ok.disabled.focus, .lobibox .lobibox-btn.lobibox-btn-ok.disabled:active, .lobibox .lobibox-btn.lobibox-btn-ok.disabled:focus, .lobibox .lobibox-btn.lobibox-btn-ok.disabled:hover, .lobibox .lobibox-btn.lobibox-btn-ok[disabled], .lobibox .lobibox-btn.lobibox-btn-ok[disabled].active, .lobibox .lobibox-btn.lobibox-btn-ok[disabled].focus, .lobibox .lobibox-btn.lobibox-btn-ok[disabled]:active, .lobibox .lobibox-btn.lobibox-btn-ok[disabled]:focus, .lobibox .lobibox-btn.lobibox-btn-ok[disabled]:hover, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok.active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok.focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok:active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok:focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok:hover {
	background-color: #0760B3;
	border-color: #0760B3
}

.lobibox .lobibox-btn.lobibox-btn-ok .badge {
	color: #0760B3;
	background-color: #FFF
}

.lobibox .lobibox-btn.lobibox-btn-default {
	color: #000;
	background-color: #e2e2e2;
	border-color: #dadada
}

.lobibox .lobibox-btn.lobibox-btn-default.active, .lobibox .lobibox-btn.lobibox-btn-default.focus, .lobibox .lobibox-btn.lobibox-btn-default:active, .lobibox .lobibox-btn.lobibox-btn-default:focus, .lobibox .lobibox-btn.lobibox-btn-default:hover, .open>.dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-default {
	color: #000;
	background-color: #c9c9c9;
	border-color: #bcbcbc
}

.lobibox .lobibox-btn.lobibox-btn-default.disabled, .lobibox .lobibox-btn.lobibox-btn-default.disabled.active, .lobibox .lobibox-btn.lobibox-btn-default.disabled.focus, .lobibox .lobibox-btn.lobibox-btn-default.disabled:active, .lobibox .lobibox-btn.lobibox-btn-default.disabled:focus, .lobibox .lobibox-btn.lobibox-btn-default.disabled:hover, .lobibox .lobibox-btn.lobibox-btn-default[disabled], .lobibox .lobibox-btn.lobibox-btn-default[disabled].active, .lobibox .lobibox-btn.lobibox-btn-default[disabled].focus, .lobibox .lobibox-btn.lobibox-btn-default[disabled]:active, .lobibox .lobibox-btn.lobibox-btn-default[disabled]:focus, .lobibox .lobibox-btn.lobibox-btn-default[disabled]:hover, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default.active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default.focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default:active, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default:focus, fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default:hover {
	background-color: #e2e2e2;
	border-color: #dadada
}

.lobibox .lobibox-btn.lobibox-btn-default .badge {
	color: #e2e2e2;
	background-color: #000
}

.lobibox.lobibox-hidden {
	display: none
}

.lobibox-backdrop {
	position: fixed;
	z-index: 4000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5)
}

.lobibox-notify-wrapper, .lobibox-notify-wrapper-large {
	z-index: 5000;
	position: fixed
}

.lobibox-notify-wrapper.top {
	top: 0
}

.lobibox-notify-wrapper.left {
	left: 0;
	margin-right: 0
}

.lobibox-notify-wrapper.right {
	right: 0;
	margin-left: 0
}

.lobibox-notify-wrapper.right .lobibox-notify {
	margin-left: auto
}

.lobibox-notify-wrapper.center {
	left: 50%
}

.lobibox-notify-wrapper-large.top {
	top: 0
}

.lobibox-notify-wrapper-large.left {
	left: 0
}

.lobibox-notify-wrapper-large.left .lb-notify-tabs>li {
	float: left;
	margin-left: 0;
	margin-right: 2px
}

.lobibox-notify-wrapper-large.right {
	right: 0
}

.lobibox-notify-wrapper-large .lb-notify-tabs {
	list-style: none;
	padding: 0;
	margin: 0 0 -5px
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li {
	float: right;
	margin-left: 2px
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li>a {
	text-align: center;
	display: table;
	text-decoration: none;
	font-size: 18px;
	height: 32px;
	color: #FFF;
	width: 28px;
	opacity: .6
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li>a:active, .lobibox-notify-wrapper-large .lb-notify-tabs>li>a:focus, .lobibox-notify-wrapper-large .lb-notify-tabs>li>a:hover, .lobibox-notify-wrapper-large .lb-notify-tabs>li>a:hover:active {
	color: #FFF
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li>a .tab-control-icon {
	display: table-cell;
	vertical-align: middle
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-default>a {
	background-color: rgba(28, 28, 28, .9);
	border-color: #141414
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-default>a:hover {
	background-color: #1c1c1c;
	border-color: #0f0f0f
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-error>a {
	background-color: rgba(202, 33, 33, .9);
	border-color: #bd1f1f
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-error>a:hover {
	background-color: #CA2121;
	border-color: #b41d1d
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-success>a {
	background-color: rgba(41, 184, 126, .9);
	border-color: #26ab75
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-success>a:hover {
	background-color: #29B87E;
	border-color: #24a370
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-warning>a {
	background-color: rgba(206, 129, 46, .9);
	border-color: #c1792b
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-warning>a:hover {
	background-color: #CE812E;
	border-color: #b97429
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-info>a {
	background-color: rgba(46, 121, 180, .9);
	border-color: #2b71a8
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.lobibox-notify-info>a:hover {
	background-color: #2E79B4;
	border-color: #296ba0
}

.lobibox-notify-wrapper-large .lb-notify-tabs>li.active>a {
	opacity: 1
}

.lobibox-notify-wrapper-large .lb-notify-tabs:after {
	content: "";
	display: block;
	clear: both
}

.lobibox-notify-wrapper-large .lb-notify-wrapper {
	background-color: transparent;
	padding: 0;
	border: none
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lb-tab-pane {
	display: none
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lb-tab-pane.active {
	display: block
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lobibox-notify {
	min-height: 150px
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lobibox-notify .lobibox-notify-icon-wrapper {
	width: 100px
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lobibox-notify .lobibox-notify-icon>div .icon-el {
	font-size: 78px
}

.lobibox-notify-wrapper-large .lb-notify-wrapper .lobibox-notify .lobibox-notify-body {
	margin: 13px 20px 13px 130px
}

.lobibox-notify {
	position: relative;
	min-height: 85px;
	font-size: 14px;
	margin: 7px 0;
	border-radius: 0;
	border: 1px solid transparent;
	-webkit-box-shadow: 2px 2px 5px #aaa;
	box-shadow: 2px 2px 5px #aaa;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s
}

.lobibox-notify .lobibox-notify-icon-wrapper {
	position: absolute;
	left: 15px;
	width: 60px;
	height: 100%
}

.lobibox-notify .lobibox-notify-icon {
	display: table;
	width: 100%;
	height: 100%
}

.lobibox-notify .lobibox-notify-icon>div {
	display: table-cell;
	vertical-align: middle
}

.lobibox-notify .lobibox-notify-icon>div>img {
	width: 100%;
	max-width: 100%;
	margin-top: 3px;
	border-radius: 4px
}

.lobibox-notify .lobibox-notify-icon>div .icon-el {
	text-align: center;
	font-size: 55px
}

.lobibox-notify .lobibox-notify-body {
	margin: 10px 20px 10px 90px
}

.lobibox-notify .lobibox-notify-title {
	font-size: 20px
}

.lobibox-notify .lobibox-notify-msg {
	overflow: hidden
}

.lobibox-notify .lobibox-close {
	position: absolute;
	text-align: center;
	border-radius: 50%;
	right: 10px;
	top: 10px;
	font-size: 20px;
	line-height: 19px;
	width: 19px;
	height: 19px;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s
}

.lobibox-notify .lobibox-close:hover {
	background-color: rgba(0, 0, 0, .5);
	font-weight: 700
}

.lobibox-notify .lobibox-delay-indicator {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 3px
}

.lobibox-notify .lobibox-delay-indicator>div {
	position: relative;
	height: 100%;
	width: 0;
	background-color: #e8e8e8
}

.lobibox-notify.lobibox-notify-default {
	border-color: #212529;
	background-color: #212529;
	color: #FFF
}

.lobibox-notify.lobibox-notify-default:hover {
	background-color: #212529;
	border-color: #FFF
}

.lobibox-notify.lobibox-notify-default .lobibox-close:hover {
	background-color: #4f4f4f
}

.lobibox-notify.lobibox-notify-error {
	border-color: #f41127;
	background-color: #f41127;
	color: #FFF
}

.lobibox-notify.lobibox-notify-error:hover {
	background-color: #f41127;
	border-color: #FFF
}

.lobibox-notify.lobibox-notify-success {
	border-color: #17a00e;
	background-color: #17a00e;
	color: #FFF
}

.lobibox-notify.lobibox-notify-success:hover {
	background-color: #17a00e;
	border-color: #FFF
}

.lobibox-notify.lobibox-notify-warning {
	border-color: #ffc107;
	background-color: #ffc107;
	color: #212529
}

.lobibox-notify.lobibox-notify-warning:hover {
	background-color: #ffc107;
	border-color: #212529
}

.lobibox-notify.lobibox-notify-info {
	border-color: #31d2f2;
	background-color: #31d2f2;
	color: #000
}

.lobibox-notify.lobibox-notify-info:hover {
	background-color: #31d2f2;
	border-color: #000
}

.lobibox-notify.rounded {
	border-radius: 30px
}

.lobibox-notify:hover {
	cursor: pointer;
	-webkit-box-shadow: 3px 3px 10px #aaa;
	box-shadow: 3px 3px 10px #aaa
}

.lobibox-notify.notify-mini {
	min-height: 36px
}

.lobibox-notify.notify-mini .lobibox-notify-title {
	margin-top: -5px;
	font-size: 20px;
	line-height: 22px
}

.lobibox-notify.notify-mini .lobibox-notify-msg {
	line-height: 16px
}

.lobibox-notify.notify-mini .lobibox-notify-icon-wrapper {
	left: 12px;
	width: 32px
}

.lobibox-notify.notify-mini .lobibox-notify-icon>div .icon-el {
	font-size: 32px
}

.lobibox-notify.notify-mini .lobibox-notify-body {
	margin: 15px 30px 15px 56px
}

.lobibox-notify.without-icon .lobibox-notify-body {
	margin-left: 20px
}