.upload-head {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px #000 dashed;
    border-radius: 4px;
    width: 100%;
}
.upload-head i.bx.bxs-cloud-upload {
    font-size: 60px;
    display: block;
    color: #10405099;
}
.custom-image-col img {
	object-fit: cover;
	width: 100%; 
	height: 150px; 
	padding: 2px; 
	border: solid 1px #896ce7
}
.custom-image-col {
    display: grid;
}
.upload-body .upload-body-detail {
	overflow-x: hidden;
    height: calc(70vh - 185px) !important;
    margin: 0px;
    border-radius: 6px;
    padding: 10px 0;
    background-color: #fff;
}
.modal-body-bg-eee {
    background-color: #eee;
}
.upload-body-detail::-webkit-scrollbar {
    width: 2px;
	border-radius: 4px;
}
 
.upload-body-detail::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.chosee-image-list {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}
.chosee-image-list .chosee-image-item {
    width: 80px;
    margin: 5px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    padding: 3px;
}
.upload-body-detail::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.chosee-image-item {
	width: 160px;
	height: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.chosee-image-item img {
    display: flex;
    width: 100%;
    height: auto;
    object-fit: cover;
    justify-content: center;
    align-items: center;
}
.imageuploadify-images-list label {
    cursor: pointer;
}